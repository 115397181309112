import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  render() {
    return (
      <div className="header-outer">
        <div className="header-inner">
          <div className="header-title">
            <Link to="/">
              <img className="coblah_title" alt="coblah_title" src="img/coblah_title.png" />
            </Link>
          </div>
          <div className="header-nav">
            <div className="about">
              <Link to="/about">
                About
              </Link>
            </div>
            <div className="service">
              <Link to="/service">
                Service
              </Link>
            </div>
          </div>
          <div className="career">
            <Link to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
