import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './header';
import Main from './main';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
//import Footer from './footer';
import NotFound from './notfound';
import Contact from './contact';
import { BrowserView, MobileView } from 'react-device-detect';

// ========================================

const App = () => {
	return (
    <div className='App' style={{background: 'none'}}>
			<BrowserView>
	      <BrowserRouter>
	        <Header />
	    		<Routes>
	    			<Route path="/" element={<NotFound />}></Route>
						<Route path="/privacy/team5" element={<PrivacyPolicy />}></Route>
						<Route path="/tos/team5" element={<TermsOfService />}></Route>
	          <Route path="/contact" element={<Contact />}></Route>
	    			{/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
	    			<Route path="*" element={<NotFound />}></Route>
	    		</Routes>
	    	</BrowserRouter>
			</BrowserView>
			<MobileView>
	      <BrowserRouter>
	        <Header />
	    		<Routes>
	    			<Route path="/" element={<Main />}></Route>
						<Route path="/privacy/team5" element={<PrivacyPolicy />}></Route>
						<Route path="/tos/team5" element={<TermsOfService />}></Route>
	          <Route path="/contact" element={<Contact />}></Route>
	    			{/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
	    			<Route path="*" element={<NotFound />}></Route>
	    		</Routes>
	    	</BrowserRouter>
			</MobileView>
		</div>

	);
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);
