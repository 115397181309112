import React, { useState } from "react";

function TermsOfService() {
  const [language, setLanguage] = useState("한글");
  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const policyFileName = language === "한글" ? "koreanTos.html" : "englishTos.html";

  const policyContent = require(`./toss/${policyFileName}`);

  return (
    <div>
      <label htmlFor="language-select">Language:</label>
      <select id="language-select" value={language} onChange={handleChangeLanguage}>
        <option value="한글">한글</option>
        <option value="영어">English</option>
      </select>
      <hr />
      {/* 개인정보 처리방침 파일의 내용을 출력합니다. */}
      <div dangerouslySetInnerHTML={{ __html: policyContent.default }}></div>
    </div>
  );
}

export default TermsOfService
