import React from 'react';
import './main.css';
import { FaUsers, FaRegComments } from "react-icons/fa";

class Main extends React.Component {
  render() {
    return (
      <div className="main-inner">
        <div className="main-header-top">
        </div>
        <div className="main-header-mid"  style={{background: 'url(/img/tile_background_black.png)'}}>
          <div className="main-header-box">
            <div className="main-header-title">
              <strong>
                Co<FaUsers /> + Blah<FaRegComments /> = 코브라
              </strong>
            </div>
            <div className="main-header-contents">
              코브라는 커뮤니티 플랫폼에 주력하며, 데이터 전문기업을 지향합니다.
            </div>
          </div>
        </div>
        <div className="main-contents-top">
        </div>
        <div className="creed">
          <h1>아무도 눈여겨보지 않는 문제를 찾습니다.</h1>
          그리고 문제를 해결하기 위해 최선을 다합니다.
        </div>
      </div>
    );
  }
}

export default Main;
