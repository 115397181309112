import React from 'react';
import './contact.css';
import axios from "axios";

function Popup(props) {
  return (
    <div className="popup">
      <h2>{props.title}</h2>
      <p>{props.message}</p>
    </div>
  );
}

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      inputName: "",
      inputPoint: "",
      inputContent: "",
      error: "",
      showPopup: false,
    };
  }

  handleClick = (event) => {
    event.preventDefault();
    const { inputName, inputPoint, inputContent, showPopup } = this.state;
    if (!inputName) {
      this.setState({
        showPopup: !this.state.showPopup,
      });
      this.setState({ error: "Please enter a value." });
      console.log(this.state.showPopup)
      return ;
    }

    this.setState({ loading: true });
    axios
      .post("https://noticelog.co.kr/api/v1/account/feedback/", { nickname:inputName, contact_point:inputPoint, feedback_content:inputContent})
      .then((response) => {
        console.log(response.data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  handleNameChange = (event) => {
    this.setState({
      inputName: event.target.value
    });
  };

  handlePointChange = (event) => {
    this.setState({
      inputPoint: event.target.value
    });
  };

  handleContentChange = (event) => {
    this.setState({
      inputContent: event.target.value
    });
  };

  render() {
    const { inputName, inputPoint, inputContent, error, loading, showPopup } = this.state;

    return (
      <div className="contact-inner">
        <div className="contact-header-top">
        </div>
        <div className="contact-header-mid" style={{background: 'url(/img/CocoaBeach.jpg)'}}>
          <div className="contact-header-box">
            <div className="contact-header-title">
              Contact Us
            </div>
          </div>
        </div>
        <div className="contact-header-gap">
        </div>
        <div className="contact-title">
          <strong>
            Request Information
          </strong>
        </div>
        <div className="contact-form">
          <form>
            <label htmlFor="name">이름</label>
            <input
              className="contact_input"
              id="name"
              type="text"
              name="name"
              value={inputName}
              onChange={this.handleNameChange}
              placeholder="홍길동"
            />
          </form>
          <div className="form-gap">
          </div>
          <form>
            <label htmlFor="email">이메일</label>
            <input
              className="contact_input"
              id="email"
              type="text"
              name="email"
              value={inputPoint}
              onChange={this.handlePointChange}
              placeholder="asd@asd.com"
            />
          </form>
          <div className="form-gap">
          </div>
          <form>
            <label htmlFor="content">문의내용</label>
            <textarea
              className="contact_textarea"
              id="content"
              type="text"
              name="content"
              value={inputContent}
              onChange={this.handleContentChange}
              placeholder=""
              rows="5"
            />
          </form>
          <div className="form-gap">
          </div>
          <button
            className="contact-button"
            type="submit"
            onClick={this.handleClick}
            disabled={loading}
          >
            {loading ? "Sending..." : "SEND >"}
          </button>
          {this.state.showPopup ? (
            <Popup
              title="필수 정보 오류"
              message="이름을 입력해 주세요."
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default Contact;
